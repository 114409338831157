export enum BASE_URL {
  PRODUCTION = 'https://metadata-api.legitimate.tech', // AWS Heroku Virginia
  STAGING = 'https://dev-metadata-api.legitimate.tech', // AWS Heroku Virginia (Staging)
}

export enum ENVIRONMENT {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export const ENVIRONMENT_LABELS: Record<string, string> = {
  [ENVIRONMENT.PRODUCTION]: 'Production',
  [ENVIRONMENT.STAGING]: 'Staging',
  [ENVIRONMENT.DEVELOPMENT]: 'Development',
};

export enum NFT_SERVER_BASE_URL {
  PRODUCTION = 'https://api.legitimate.tech', // Production Wallet
  STAGING = 'https://dev-api.legitimate.tech', // Testing Wallet
}

export const LGT_SERVER_URL = process.env.REACT_APP_LGT_SERVER_URL || BASE_URL.STAGING;
export const NFT_SERVER_URL = process.env.REACT_APP_NFT_SERVER_URL || NFT_SERVER_BASE_URL.STAGING;


export const API_STAGE = process.env.REACT_APP_API_STAGE || ENVIRONMENT.DEVELOPMENT as ENVIRONMENT;
