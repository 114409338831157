import { LGT_SERVER_URL } from "./baseUrl";
import { NetworkRequestError } from "./error";
import { TOrganization } from "./organizations";

export type TWorkOsSession = {
  authenticated: boolean;
  session_id: string;
  organization_id: string;
  role: string;
  permissions: string[];
  user: TWorkOsUser;
  impersonator: string;
}

export type TWorkOsUser = {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  email_verified: boolean;
  profile_picture_url: string | null;
  created_at: string;
  updated_at: string;
}

export const LOGIN_URL = `${LGT_SERVER_URL}/auth/login?redirect_uri=${window.location.origin}/auth/callback`;
export const LOGOUT_URL = 'https://api.workos.com/user_management/sessions/logout?session_id=';

export const getSession = async () => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/auth/session`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TWorkOsSession = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export type TWorkOsOrganization = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export const getOrganization = async () => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/auth/organization`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TWorkOsOrganization = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const refreshSession = async (
  organization_id?: string,
) => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/auth/session`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organization_id,
      }),
    });

    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: {
      token: string;
    } = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export type OrganizationMembership = {
  id: string,
  userId: string,
  organizationId: string,
  organizationName: string,
  role: {
    slug: string,
  },
  status: 'active' | 'pending' | 'inactive',
  createdAt: string,
  updatedAt: string,
};

export const getUserOrgDetails = async () => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/auth/organization/details`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: {
      workos_org: TWorkOsOrganization,
      organization: TOrganization,
      workos_org_users: TWorkOsUser[],
    } = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
}

type TAuditLogExport = {
  id: string;
  state: 'ready' | 'pending' | 'error';
  url: string;
  created_at: string;
  updated_at: string;
}

export const getAuditLog = async () => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/auth/organization/logs/request`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TAuditLogExport = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
}

export const downloadAuditLog = async (export_id: string) => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/auth/organization/logs/${export_id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TAuditLogExport = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
}
