import { LGT_SERVER_URL, NFT_SERVER_URL } from "./baseUrl";
import { TTemplate } from "./templates";

export interface TTransaction{
  hash: string;
}

export enum LGTContractVersion {
  LEGACY_UNSUPPORTED = 0,
  V3_BASE_URI = 1,
  V3_PSI_BASE_URI = 2,
  V3_PSI_ON_CHAIN_METADATA = 3,
  ERC721_NFT_DISTRIBUTION = 4,
  ERC1155_NFT_DISTRIBUTION = 5,
}

export const deployContract = async (
  templateId: number,
  tokenName: string,
  tokenSymbol: string,
) => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/templates/${templateId}/deploy`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token_name: tokenName,
        token_symbol: tokenSymbol,
      }),
    });
    const responseBody: TTransaction = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export interface TUpdateNftMetadata {
  name?: string;
  description?: string;
  image_url?: string;
  animation_url?: string;
}

export interface TUpdateBaseUri {
  baseUri?: string;
}

export const updateMetadata = async (
  metadata: TUpdateNftMetadata,
  template: TTemplate,
) => {
  try {
    const response = await fetch(`${NFT_SERVER_URL}/dashboard/contract/metadata`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        template,
        metadata,
      }),
    });
    if (!response.ok) throw new Error(response.statusText)
    const responseBody: TTransaction[] = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const mintNfts = async (
  templateId: number,
) => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/templates/${templateId}/mint`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) throw new Error(response.statusText)
    const responseBody: TTransaction[] = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const updateBaseUri = async (
  templateId: number,
  uri: string,
) => {
  try {
    const response = await fetch(`${NFT_SERVER_URL}/dashboard/contract/uri`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        templateId,
        uri,
        baseUrl: LGT_SERVER_URL,
      }),
    });
    if (!response.ok) throw new Error(response.statusText)
    const responseBody: TTransaction = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};
