import { Box, Container, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid";
import { getOrganizations } from "../utils/network/organizations";
import { useQuery } from "@tanstack/react-query";

const columns = [
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 200,
    type: 'dateTime',
    valueGetter: (params: any) => {
      return new Date(params.row.created_at);
    }
  },
  {
    field: 'workos_organization_id',
    headerName: 'Organization ID',
    type: 'string',
    width: 320,
  },
  {
    field: 'name',
    headerName: 'Organization Name',
    width: 300,
    type: 'string',
  },
  {
    field: 'templates_count',
    headerName: 'SKUs',
    width: 80,
    type: 'number',
  },
  {
    field: 'tags_count',
    headerName: 'Tags',
    width: 120,
    type: 'number',
  },
  {
    field: 'tag_verifications_count',
    headerName: 'Tag Verifications',
    width: 150,
    type: 'number',
  },
  {
    field: 'external_tag_verifications_count',
    headerName: 'API Tag Verifications',
    width: 150,
    type: 'number',
  },
]

const Users = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['Users'],
    queryFn: async () => {
      return await getOrganizations()
    }
  })

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">Organizations</Typography>
      <Box sx={{ height: 20, width: '100%' }} />
      <DataGrid
        autoHeight
        getRowId={(row) => row.workos_organization_id}
        rows={data || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        pageSizeOptions={[25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Container>
  )
}

export default Users
