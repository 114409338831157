
import { Avatar, Box, Button, Container, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import { downloadAuditLog, getAuditLog, getUserOrgDetails } from "../utils/network/auth";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";


const Organization = () => {
  const [isRequesting, setIsRequesting] = useState(false)
  const [auditExportId, setAuditExportId] = useState<string>('')

  const { data } = useQuery({
    queryKey: ['Organization'],
    queryFn: getUserOrgDetails,
  })

  const { data: auditLogExport } = useQuery({
    queryKey: ['AuditLog', auditExportId],
    queryFn: () => downloadAuditLog(auditExportId),
    enabled: !!auditExportId,
    refetchInterval: (query) => {
      if(query.state.data?.state !== 'ready') {
        return 1000;
      }
      return false;
    }
  })

  const handleDownloadLogs = async () => {
    setIsRequesting(true)
    const logExport = await getAuditLog();

    setAuditExportId(logExport.id)
    setIsRequesting(false)
  }

  return (
    <Container maxWidth="sm">
      <Stack spacing={2}>
        <Typography variant="h2">Organization</Typography>
        <Box>
          <Typography variant="body1">
            {data?.workos_org?.name}
          </Typography>
          <Typography variant="caption" color="secondary">
            Name
          </Typography>
          <Typography variant="body1">
            {data?.workos_org?.id}
          </Typography>
          <Typography variant="caption" color="secondary">
            Organization ID
          </Typography>
        </Box>
        <Typography variant="h4">Usage</Typography>
        <Box>
          <Typography variant="body1">
            {data?.organization?.templates_count}
          </Typography>
          <Typography variant="caption" color="secondary">
            Total SKUs
          </Typography>
          <Typography variant="body1">
            {data?.organization?.tags_count}
          </Typography>
          <Typography variant="caption" color="secondary">
            Total Tags
          </Typography>
          <Typography variant="body1">
            {data?.organization?.tag_verifications_count}
          </Typography>
          <Typography variant="caption" color="secondary">
            Total Tag Verifications
          </Typography>
        </Box>
        <Typography variant="h4">Users</Typography>
        <Box>
          <List dense>
          {data?.workos_org_users?.map((user) => {
            return <ListItem key={user.id} disableGutters>
              <ListItemAvatar>
                <Avatar src={user.profile_picture_url || ''} >
                  {user?.first_name?.charAt(0).toUpperCase() || user?.email.charAt(0).toUpperCase()}{user?.last_name?.charAt(0).toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email + ` (${user.id})`} />
            </ListItem>
          })}
          </List>
        </Box>
        <Typography variant="h4">Audit Log</Typography>
        <Typography variant="body2" color="secondary">
          Request to download a 30-day history of all activities in your organization.
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleDownloadLogs}
          disabled={isRequesting || !!auditExportId}
        >
          Request Audit Logs
        </Button>
        <Button variant="contained" color="primary" onClick={handleDownloadLogs} disabled={!auditLogExport?.url}
          href={auditLogExport?.url || ''} target="_blank" download
        >
          Download Audit Logs
        </Button>
      </Stack>
    </Container>
  )

}

export default Organization
