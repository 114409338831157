import { LGT_SERVER_URL } from "./baseUrl";
import { NetworkRequestError } from "./error";

export interface TOrganization {
  workos_organization_id: string;
  updated_at: number;
  created_at: number;
  name: string;
  tags_count: number;
  templates_count: number;
  tag_verifications_count: number;
  external_tag_verifications_count: number;
}

export enum LEGITIMATE_ORGS {
  PRODUCTION = 'org_01JAYZSTRNP5WZTTQ6ZQHB6R9C',
  STAGING = 'org_01J8GV7RK5YAJZPJD7Y176MCZ2',
}

export const isLegitimateOrg = (orgId?: string) => {
  return orgId === LEGITIMATE_ORGS.PRODUCTION || orgId === LEGITIMATE_ORGS.STAGING;
}

export const getOrganizations = async () => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/organizations`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TOrganization[] = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};


export const createOrganization = async (
  name: string,
) => {
  try {
    const response = await fetch(`${LGT_SERVER_URL}/organizations/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name }),
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TOrganization = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
}
