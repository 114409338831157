import { Avatar, Box, Chip, Container, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../utils/auth/context";

const Profile = () => {
  const { data: session } = useContext(AuthContext);
  const user = session?.user;

  return (
    <Container maxWidth="sm">
      <Stack spacing={2}>
        <Typography variant="h2">User Profile</Typography>
        <Avatar src={user?.profile_picture_url || ''} sx={{ width: 96, height: 96 }}>
          {user?.first_name?.charAt(0).toUpperCase() || user?.email.charAt(0).toUpperCase()}{user?.last_name?.charAt(0).toUpperCase()}
        </Avatar>
        <Box>
          <Typography variant="body1">
            {user?.first_name || user?.last_name ? `${user?.first_name} ${user?.last_name}` : 'N/A'}
          </Typography>
          <Typography variant="caption" color="secondary">
            First and Last Name
          </Typography>
          <Typography variant="body1">
            {user?.email}
          </Typography>
          <Typography variant="caption" color="secondary">
            Email
          </Typography>
          <Typography variant="body1">
            {user?.id}
          </Typography>
          <Typography variant="caption" color="secondary">
            User ID
          </Typography>
          <Typography variant="body1">
            {session?.role}
          </Typography>
          <Typography variant="caption" color="secondary">
            Role
          </Typography>
          <Typography variant="body1">
            {session?.permissions.map((p) => {
              return <Chip label={p} variant="outlined" sx={{ marginTop: 0.25, marginRight: 0.25 }} />
            })}
          </Typography>
          <Typography variant="caption" color="secondary">
            Permissions
          </Typography>
          <Typography variant="body1">
            {session?.session_id}
          </Typography>
          <Typography variant="caption" color="secondary">
            Current Session ID
          </Typography>
        </Box>
        <Typography variant="body2" color="secondary">
          You can change your password during login by clicking the "Forgot Password" option.
        </Typography>
      </Stack>
    </Container>
  )

}

export default Profile
