import React, { ImgHTMLAttributes } from 'react';

import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  containerProps?: BoxProps;
};

const lgtIpfsGatewayBaseUrl = 'ipfs.legitimate.tech/ipfs/';

const srcSetWidths = [200, 400, 800, 1200, 1600, 2000, 3000, 4000];

export const Image = ({ src, containerProps, alt, ...props }: ImageProps) => {

  const isLgtPinata = src.includes(lgtIpfsGatewayBaseUrl);
  const isIpfsProtocol = src.includes('ipfs://');

  const getPinataImgByWidth = (width: number) => {
    return `${applyLgtIpfsGateway(src)}?img-width=${width}&img-format=auto`;
  };

  const getSourceSet = () => {
    return srcSetWidths
      .map((width) => {
        return `${getPinataImgByWidth(width)} ${width}w`;
      })
      .join(', ');
  };

  return (
    <ImageContainer {...containerProps}>
      <picture>
        {isLgtPinata && <source srcSet={getSourceSet()} />}
        <img
          src={isIpfsProtocol ? applyCloudflareIpfsGateway(src): src}
          {...props}
          alt={alt}
        />
      </picture>
    </ImageContainer>
  );
};

const applyLgtIpfsGateway = (imageUrl: string) => {
  return imageUrl.replace('ipfs.io', 'ipfs.legitimate.tech');
};

const applyCloudflareIpfsGateway = (imageUrl: string) => {
  return imageUrl.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
}

const ImageContainer = styled(Box)`
  img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }
`;
