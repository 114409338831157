import { Alert, Button, CircularProgress, Link, Stack, TextField } from "@mui/material";
import { TTemplate } from "../../utils/network/templates";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { NETWORK, NETWORK_NAME_BY_CHAIN_ID, generateAddressLink } from "../../utils/web3";
import { deployContract } from "../../utils/network/contract";

interface IContractDeployForm {
  tokenName: string;
  tokenSymbol: string;
}

const DeployContract = ({ template, disabled, onChange, tokenName, tokenSymbol } :
  { template: TTemplate, disabled: boolean, onChange: () => Promise<any>, tokenName?: string, tokenSymbol?: string }) => {

    const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IContractDeployForm>({
    shouldUseNativeValidation: true,
    values: {
      tokenName: tokenName || template?.name?.replaceAll(/\s/g, '').slice(0, 25) || '',
      tokenSymbol: tokenSymbol || template?.name?.replaceAll(/\s/g, '').slice(0, 15) || '',
    },
  });

  const [isDeploying, setIsDeploying] = useState(false);

  const handleContractDeploy = async (data: IContractDeployForm) => {
    setIsDeploying(true);

    const { tokenName, tokenSymbol } = data;

    await deployContract(template.id, tokenName, tokenSymbol);

    await onChange();
    setIsDeploying(false);
  }

  const contractAddress = template?.nft_contract_address;

  return (
    <form onSubmit={handleSubmit(handleContractDeploy)}>
      <Stack spacing={2}>
        <Stack spacing={2} direction="row">
          <TextField type="text"
            {...register("tokenName", { required: true })}
            label="Collection Identifier"
            required
            autoComplete="off"
            disabled={disabled}
            error={!!errors.tokenName}
            inputProps={{ maxLength: 25 }}
            helperText="This is the token name for the Digital Product Passport. 25 characters max, no spaces."
          />
          {<TextField type="text"
            {...register("tokenSymbol", { required: true })}
            error={!!errors.tokenSymbol}
            label="Collection Shorthand ID"
            required
            autoComplete="off"
            disabled={disabled}
            inputProps={{ maxLength: 15 }}
            helperText="This is the symbol of the Digital Product Passport. 15 characters max, no spaces."
          />}
        </Stack>
        {(!template?.contract_version || !template?.nft_chain_id) && <Alert severity="info">
          Please select the blockchain and digital product passport type before deploying.
        </Alert>}
        {contractAddress && <Alert severity="success">
          Contract successfully deployed to <Link href={generateAddressLink(template.nft_chain_id as NETWORK, contractAddress)} target="_blank">{contractAddress}</Link> on {NETWORK_NAME_BY_CHAIN_ID[template.nft_chain_id as NETWORK]} and verified.
        </Alert>}
        <Button type="submit"
          variant="contained"
          disabled={!!contractAddress || !template.nft_chain_id || !template.contract_version || disabled || isDeploying}
          startIcon={isDeploying && <CircularProgress size={16} />}
        >
          {!template.nft_chain_id && 'Select Blockchain to Deploy'}
          {template.nft_chain_id && `Deploy to ${NETWORK_NAME_BY_CHAIN_ID[template.nft_chain_id as NETWORK]}`}
        </Button>
      </Stack>
    </form>
  );
}

export default DeployContract;
