import { Navigate, useSearchParams } from "react-router-dom"
import { LGT_SERVER_URL } from "./utils/network/baseUrl";
import { useQuery } from "@tanstack/react-query";
import { Alert, LinearProgress } from "@mui/material";

const Callback = () => {
  const [searchParams] = useSearchParams();

  const state = searchParams.get('state');

  const { isLoading, isError, error } = useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      const resp = await fetch(`${LGT_SERVER_URL}/auth/callback?${searchParams.toString()}`)
      const data = await resp.json()

      const token = data.token;

      if (token) {
        localStorage.setItem('token', token);
      } else {
        throw new Error('No token found in response');
      }

      return data;
    },
  })

  if (isError) {
    return <Alert severity="error">
      Error logging in.
      {JSON.stringify(error)}
    </Alert>
  }

  if (isLoading) {
    return <LinearProgress />
  }

  return <Navigate to={state || '/skus'} />
}

export default Callback;
