import { Alert, Button, CircularProgress, Stack, Link } from "@mui/material";
import { TTemplate } from "../../utils/network/templates";
import { JsonRpcProvider } from "ethers";
import { useCallback, useMemo, useState } from "react";
import {
  TTransaction,
  TUpdateNftMetadata,
  updateMetadata,
} from "../../utils/network/contract";
import { NETWORK, generateTxLink } from "../../utils/web3";
import { TContractData } from "../../utils/web3/primaryContractData";

export const SyncMetadata = ({
  template,
  nftTitle,
  nftDescription,
  nftImageUri,
  nftAnimationUri,
  onChange,
  disabled,
  provider,
}: {
  onChange: () => void;
  template: TTemplate;
  disabled: boolean;
  provider?: JsonRpcProvider;
} & TContractData) => {
  const [isSaving, setIsSaving] = useState(false);

  const [txProgress, setTxProgress] = useState<{
    completed: TTransaction[];
    inProgress: TTransaction[];
  }>({
    completed: [],
    inProgress: [],
  });

  const isSyncedOnchain = useMemo(() => {
    if (template?.contract_version !== 3) {
      return false;
    }

    return (
      (!template?.name || nftTitle === template?.name) &&
      (!template?.description || nftDescription === template?.description) &&
      (!template?.image_url || nftImageUri === template?.image_url) &&
      (!template?.animation_url || nftAnimationUri === template?.animation_url)
    );
  }, [template, nftDescription, nftImageUri, nftAnimationUri, nftTitle]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);

    const nftMetadataUpdate = {} as TUpdateNftMetadata;

    if (template.name && nftTitle !== template.name) {
      nftMetadataUpdate["name"] = template.name;
    }

    if (template.description && nftDescription !== template.description) {
      nftMetadataUpdate["description"] = template.description;
    }
    if (template.image_url && nftImageUri !== template.image_url) {
      nftMetadataUpdate["image_url"] = template.image_url;
    }
    if (template.animation_url && nftAnimationUri !== template.animation_url) {
      nftMetadataUpdate["animation_url"] = template.animation_url;
    }

    const txList = await updateMetadata(nftMetadataUpdate, template);

    await Promise.all(
      txList.map(async (tx) => {
        setTxProgress((prev) => ({
          inProgress: [...prev.inProgress, tx],
          completed: prev.completed,
        }));
        (await provider?.waitForTransaction(tx.hash)) as TTransaction;
        setTxProgress((prev) => ({
          inProgress: prev.inProgress.filter(
            (inProgressTx) => tx.hash !== inProgressTx.hash,
          ),
          completed: [...prev.completed, tx],
        }));
      }),
    );

    onChange();
    setIsSaving(false);
  }, [
    nftTitle,
    nftDescription,
    nftImageUri,
    nftAnimationUri,
    template,
    provider,
    onChange,
  ]);

  return (
    <Stack spacing={2}>
      {txProgress.inProgress.length > 0 && (
        <Alert severity="info">
          Update transactions in progress...
          <br />
          {txProgress.inProgress.map((tx) => (
            <>
              <Link
                href={generateTxLink(template.nft_chain_id as NETWORK, tx.hash)}
                target="_blank"
              >
                {tx.hash}
              </Link>
              <br />
            </>
          ))}
        </Alert>
      )}
      {txProgress.inProgress.length === 0 &&
        (nftTitle || nftDescription || nftImageUri) && (
          <Alert severity="success">
            Product data saved: {nftTitle && "title "}
            {nftDescription && "description "}{nftImageUri && "image "}
            {nftAnimationUri && "animation"}
          </Alert>
        )}
      {!template?.nft_contract_address && (
        <Alert severity="info">
          Digital Product Passport must be deployed before Digital Product ID
          can be configured
        </Alert>
      )}
      {template?.nft_contract_address && !isSaving && !isSyncedOnchain && template.contract_version === 3 && (
        <Alert severity="info">
          The following values have not been synced to the Digital Product
          Passport:{" "}
          <ul>
            {template.name && nftTitle !== template.name && <li>Item Name</li>}
            {template.description &&
              nftDescription !== template.description && <li>Description</li>}
            {template.image_url &&
              nftImageUri !== template.image_url && <li>Image</li>}
            {template.animation_url &&
              nftAnimationUri !== template.animation_url && <li>Animation</li>}
          </ul>
        </Alert>
      )}
      <Button
        variant="contained"
        onClick={handleSave}
        disabled={isSaving || disabled || isSyncedOnchain}
        startIcon={isSaving && <CircularProgress size={16} />}
      >
        Sync Product Data
      </Button>
    </Stack>
  );
};
