
export enum ROLE {
  ADMIN = 'admin',
  EDITOR = 'editor',
  VIEWER = 'viewer',
  TAG_PROGRAMMER = 'tag_programmer',
}

export const ROLE_LABELS = {
  [ROLE.ADMIN]: 'Admin',
  [ROLE.EDITOR]: 'Editor',
  [ROLE.VIEWER]: 'Viewer',
  [ROLE.TAG_PROGRAMMER]: 'Tag Programmer',
}
