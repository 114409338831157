import { Button, Container, Stack, Typography, Link, Box, CircularProgress } from '@mui/material';
import { ReactComponent as Wordmark } from './assets/Wordmark.svg'
import { LOGIN_URL } from './utils/network/auth';
import { Image } from './components/Image';
import { useState } from 'react';
import HoverSymbol from './assets/HoverSymbol.svg';

export default function SplashPage() {
  const [loggingIn, setLoggingIn] = useState(false);


  return <Container maxWidth="sm">
     <Stack justifyContent="center" alignItems="center" height="calc(100vh - 70px)" display="flex" spacing={6}>
      <Typography variant="h3" sx={{ color: 'primary.main', textAlign: 'center' }}>Tag Management Dashboard</Typography>

      <Box sx={{ width: '100px' }}>
        <Image src={HoverSymbol} alt="Legitimate Tag" />
      </Box>

      <Button variant="contained"
        href={LOGIN_URL}
        sx={{ width: '200px' }}
        disabled={loggingIn}
        onClick={() => setLoggingIn(true)}
        startIcon={loggingIn ? <CircularProgress size={20} /> : null}
      >
        Login
      </Button>

      <Link href="https://legitimate.tech" target='_blank' key="logo">
        <Wordmark style={{ width: '200px' }} />
      </Link>
    </Stack>
  </Container>;
}
