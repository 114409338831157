import { Button, Stack, Typography } from "@mui/material"
import { TTemplate } from "../../utils/network/templates";

const ActivateTags = ({ template }: { template: TTemplate }) => {

  return (
    <Stack spacing={2} marginY={4}>
      <Typography variant="h4">5. Activate Legitimate Tags</Typography>
        Tags are now activated during tag programming. This step is no longer required.
      <Button variant="contained" disabled
      >
        Activate {template?.tags_count} Legitimate Tags
      </Button>
    </Stack>
  );
}

export default ActivateTags;
